<template>
  <!-- 作品 案例 -->
  <div class="zz_works">
    <div
      class="current-nav centerText"
      v-if="Object.keys(gcData).length != 0"
      :style="{
        background: `url(${gcData.gcalImg}) 100%/100% no-repeat`
      }"
    >
      <h1>{{ gcData.gcalMsg }}</h1>
      <h2>{{ gcData.gcalText }}</h2>
    </div>
    <div class="all_no_data" v-else>
      <i class="el-icon-loading"></i>
      <p>数据正在加载</p>
    </div>
    <div class="zz_works_nav" v-if="!currentId">
      <div class="container">
        <h2>工程案例</h2>
        <div class="container_nav">
          <ul v-if="alList.length != 0">
            <li
              v-for="(item, idx) in alList"
              :key="idx"
              @click="toDetails(item, idx)"
            >
              <i class="li_i"></i>
              <a class="li_img" :href="'#/zz_works?currentId=' + item.id">
                <img
                  :src="item.site_images"
                  alt=""
                  style="width:100%;min-width: 200px;"
                />
              </a>
              <div class="li_text">
                <a :href="'#/zz_works?currentId=' + item.id">
                  <h3>案例名称</h3>
                </a>
                <a :href="'#/zz_works?currentId=' + item.id" class="li_more">
                  <span>MORE</span>
                  <i class="el-icon-right"></i>
                </a>
              </div>
            </li>
          </ul>
          <div class="no_data centerText" v-else>
            暂无数据
          </div>
        </div>
        <div class="container_nav2">
          <ul v-if="alList.length != 0">
            <li
              v-for="(item, idx) in alList"
              :key="idx"
              @click="toDetails(item, idx)"
            >
              <a class="li_img" :href="'#/zz_works?currentId=' + item.id">
                <img
                  :src="item.site_images"
                  alt=""
                  style="width:100%;min-width: 200px;"
                />
              </a>
              <h4>案例名称</h4>
            </li>
          </ul>
        </div>
      </div>
      <div class="all-pagination">
        <el-pagination
          prev-text="上一页"
          next-text="下一页"
          @current-change="handleCurrentChange"
          :current-page.sync="tabPag.page"
          background
          :page-size="6"
          layout="prev, pager, next,total"
          :total="tabPag.total"
          v-if="tabPag.total > 6"
        >
        </el-pagination>
        <span class="no_meet_conditions" v-else>暂无更多数据</span>
      </div>
    </div>
    <div class="zz_works_nav2" v-else>
      <div class="works-nav2-container">
        <div class="container_head">
          <div class="container_head_left">
            <span>当前位置：</span>
            <el-breadcrumb separator-class="el-icon-arrow-right">
              <el-breadcrumb-item :to="{ path: '/zz_index1' }">首页</el-breadcrumb-item>
              <el-breadcrumb-item :to="{ path: '/zz_works' }"
                ><span @click="currentId = ''"
                  >工程案例</span
                ></el-breadcrumb-item
              >
              <el-breadcrumb-item>案例详情</el-breadcrumb-item>
            </el-breadcrumb>
          </div>
          <div class="container_head_right">
            <span>{{ alxqData.type_name }}</span>
          </div>
        </div>
        <div class="container_title">
          <h2>{{ alxqData.title }}</h2>
          <p>
            <i class="el-icon-time"></i> {{ alxqData.create_time }}
            <i class="el-icon-view"></i> {{ alxqData.reads }}
          </p>
        </div>
        <div class="container_img" v-html="alxqData.maincontent"></div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getPageMsg,
  getZzCaseList,
  getCaseDetail,
} from '@/api/api'
export default {
  components: {},
  data () {
    return {
      gcData: {},
      dataList: [],
      alList: [],
      active: 0,
      tabPag: {
        page: 1,
        total: 0
      },
      currentId: '',
      alxqData: {},
      currentEwm: '',
      xcx_ewm: false,
      project_id: ''
    }
  },
  watch: {
    $route: {
      // 监听query参数变化
      handler () {
        if (this.$route.query.currentId) {
          this.currentId = this.$route.query.currentId
          this.getData()
        } else {
          this.currentId = ''
        }
      },
      deep: true
    }
  },
  methods: {
    getData () {
      this.project_id = sessionStorage.getItem(this.changeData() + 'project')
      if (this.currentId) {
        this.currentId = this.$route.query.currentId
        this.toDetails({ id: this.currentId })
      } else {
        this.currentId = ''
      }
      let id = sessionStorage.getItem(this.changeData() + 'id')
      getPageMsg(id).then(res => {
        if (res.data.code == 1) {
          this.gcData = res.data.data.gcalMsg
        } 
      })
      let obj = {
        page: 1,
        pagesize: 6,
        type_id: '',
        project: this.project_id
      }
      this.getDataList(obj)
    },
    getDataList (e) {
      getZzCaseList(e).then(res => {
        if (res.data.code == 1) {
          this.alList = res.data.data.list
          this.tabPag.total = res.data.data.total
        }
      })
    },
    toDetails (e, i) {
      this.currentId = e.id
      getCaseDetail(e.id, this.project_id).then(res => {
        if (res.data.code == 1) {
          this.alxqData = res.data.data
        }
      })
    },
    onChangeDiv (e, i) {
      this.active = i
      let obj = {
        page: 1,
        pagesize: 6,
        type_id: e.id,
        project: this.project_id
      }
      this.getDataList(obj)
    },
    handleCurrentChange (val) {
      let obj = {
        page: val,
        pagesize: 6,
        type_id: '',
        project: this.project_id
      }
      this.getDataList(obj)
    }
  },
  mounted () {
    this.getData()
  }
}
</script>

<style lang="less" scoped>
@import url('~@/assets/css/mixins.less'); //混合
.zz_works {
  width: 100%;
  height: 100%;
  overflow: hidden;
  .zz_works_nav {
    background: #f5f5f5;
    padding: 70px 0px;
    .container {
      h2 {
        font-weight: bold;
        font-size: 22px;
        color: #333;
        position: relative;
        text-align: center;
        margin-bottom: 20px;
        color: #eb6100;
      }
      h2:after {
        content: '';
        display: block;
        width: 50px;
        height: 1px;
        background: #eb6100;
        position: absolute;
        left: 50%;
        bottom: -10px;
        margin-left: -25px;
      }
      .container_nav {
        ul {
          padding: 0 30px;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          li {
            border: 5px solid #e8e8e8;
            margin-top: 50px !important;
            width: 45%;
            max-height: 200px;
            position: relative;
            transition: ease 0.65s;
            display: flex;
            justify-content: space-between;
            .li_i:before {
              content: '';
              display: block;
              width: 0;
              height: 0;
              border-top: 5px solid;
              border-left: 5px solid;
              border-color: #eb6100;
              position: absolute;
              left: -5px;
              top: -5px;
              transition: ease 0.65s;
            }
            .li_i:after {
              content: '';
              display: block;
              width: 0;
              height: 0;
              border-right: 5px solid;
              border-bottom: 5px solid;
              border-color: #eb6100;
              position: absolute;
              right: -5px;
              bottom: -5px;
              transition: ease 0.65s;
            }
            .li_img {
              max-width: 50%;
              transform: translate(58px, -24px);
              transition: ease 0.65s;
              max-height: 200px;
              overflow-y: scroll;
              text-align: center;
            }
            .li_text {
              width: 50%;
              text-align: center;
              position: absolute;
              left: 0;
              top: 0;
              height: 100%;
              h3 {
                font-size: 20px;
                font-weight: bold;
                color: #333333;
                margin: 0px 0px 35px;
                position: relative;
                height: 22px;
                line-height: 22px;
                text-align: center;
              }
              a {
                margin: auto;
                display: block;
                margin-top: 15px;
              }
              h3:after {
                content: '';
                display: block;
                width: 40px;
                height: 1px;
                background: #cccccc;
                position: absolute;
                left: 50%;
                bottom: -15px;
                margin-left: -20px;
                transition: ease 0.65s;
              }
              .li_more {
                display: inline-block;
                width: 130px;
                height: 30px;
                line-height: 30px;
                text-align: center;
                color: #eb6100;
                background: #ffffff;
                box-shadow: 0 0 10px rgba(12, 3, 6, 0.13);
                position: relative;
                z-index: 1;
                margin: 0;
                i {
                  display: inline-block;
                  width: 30px;
                  height: 30px;
                  line-height: 30px;
                  background: #eb6100;
                  color: #ffffff;
                  font-size: 20px;
                  transition: ease 0.65s;
                  position: relative;
                  z-index: 1;
                  float: right;
                }
                i:after {
                  transition: ease-in 0.65s;
                  content: '';
                  display: block;
                  width: 0;
                  height: 100%;
                  background: #fff;
                  position: absolute;
                  left: 0;
                  top: 0;
                  z-index: -1;
                }
              }
              .li_more:hover {
                color: #fff;
                i {
                  color: #eb6100;
                  transition-delay: 0.2s;
                }
                i:after {
                  width: 100%;
                  transition-delay: 0.2s;
                  transition-timing-function: cubic-bezier(
                    0.52,
                    1.64,
                    0.37,
                    0.66
                  );
                }
              }
              .li_more::before {
                transition: ease-out 0.35s;
                content: '';
                display: block;
                width: 0;
                height: 100%;
                background: #eb6100;
                position: absolute;
                left: 0;
                top: 0;
                z-index: -1;
              }
              .li_more:hover:before {
                width: 100%;
              }
            }
          }
          li:hover .li_i:after,
          li:hover .li_i:before {
            width: 100.5%;
            height: 104%;
            visibility: visible;
          }
          li:hover .li_img {
            transform: translate(0);
          }
          li:hover .li_text h3:after {
            background: #eb6100;
          }
        }
      }
      .container_nav2 {
        display: none;
        width: 96%;
        margin: 0 auto;
        ul {
          margin-top: 1rem;
          li {
            width: 16rem;
            margin: 0 auto;
            margin-top: 1rem;
            h4{
              text-align: center;
              margin-top: 1rem;
              font-size: 0.7rem;
              font-weight: 600;
            }
          }
        }
      }
    }
  }
  .zz_works_nav2 {
    background-color: #f5f5f5;
    padding-bottom: 70px;
    .works-nav2-container {
      width: 86%;
      min-width: 1200px;
      margin: 0 auto;
      .container_head {
        padding: 18px 35px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .container_head_left {
          display: flex;
          color: #333;
          font-size: 14px;
        }
        .container_head_right {
          span {
            display: inline-block;
            padding: 15px 50px;
            color: #fff;
            font-size: 16px;
            background-color: #e62022;
          }
        }
      }
      .container_title {
        font-size: 14px;
        color: #333;
        p {
          margin-top: 10px;
          margin-bottom: 30px;
        }
      }
      .container_img {
        background-color: #fff;
        min-height: 300px;
        display: flex;
        flex-direction: column;
        align-items: center;
        /deep/ p,h1,div{
          line-height: 1.5;
        }
        img {
          display: block;
          max-width: 86%;
          height: auto !important;
          margin: auto;
          padding: 80px 0;
        }
      }
    }
  }
  .no_data {
    height: 400px;
  }
}
</style>
